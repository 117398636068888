<template>
	<template-base>
		<h1>Você não possui permissão suficiente para acessar esta página.</h1>
		<section>
			<LockIcon size="10x" class="text-dark" />
		</section>
		<div class="d-flex justify-content-between mt-4">
			<router-link
				:to="$route.params.prev || '/'"
				class="btn btn-secondary mr-2"
				>Voltar</router-link
			>
			<router-link
				:to="{ name: 'logout', params: { next: $route.params.next } }"
				class="btn btn-success text-white lg"
				>Trocar usuário</router-link
			>
		</div>
	</template-base>
</template>

<style scoped>
	section {
		height: 30vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>

<script>
	import TemplateBase from "../templates/Base";

	export default {
		components: { TemplateBase }
	};
</script>
